import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

let _userManager = null;

function init() {
  const tenantId = localStorage.getItem('tenant_id');

  if (!_userManager) {
    const settings = {
      authority: process.env.VUE_APP_OIDC_AUTHORITY,
      client_id: process.env.VUE_APP_OIDC_CLIENT_ID,
      client_secret: process.env.VUE_APP_OIDC_CLIENT_SECRET,
      redirect_uri: `${window.location.origin}${process.env.VUE_APP_BASE_PATH}signin-oidc`,
      post_logout_redirect_uri: `${window.location.origin}${process.env.VUE_APP_BASE_PATH}signout-oidc`,
      response_type: 'code',
      scope: process.env.VUE_APP_OIDC_SCOPES,
      userStore: new WebStorageStateStore(),
      loadUserInfo: true,
    };

    if (tenantId) {
      settings.authority = `${settings.authority.trimEnd('/')}/company/${tenantId}`;
    }
    _userManager = new UserManager(settings);
  }
}

async function signInRedirect(options = null) {
  if (options) {
    return await _userManager.signinRedirect(options);
  }
  return await _userManager.signinRedirect();
}

function signInCallback() {
  return _userManager.signinCallback();
}

function signoutRedirect(options = {}) {
  return _userManager.signoutRedirect({
    post_logout_redirect_uri: `${window.location.origin}${process.env.VUE_APP_BASE_PATH}signout-oidc`,
    ...options,
  });
}

function signoutCallback() {
  return _userManager.signoutCallback();
}

async function getUser() {
  const user = await _userManager.getUser();
  return user;
}

async function getAccessToken() {
  const user = await _userManager.getUser();
  return user?.access_token;
}

function changeTenantId(tenantId) {
  localStorage.setItem('tenant_id', tenantId);
}

function removeTenantId(tenantId) {
  localStorage.removeItem('tenant_id', tenantId);
}

export default {
  init,
  signInRedirect,
  signInCallback,
  getUser,
  getAccessToken,
  signoutRedirect,
  signoutCallback,
  changeTenantId,
  removeTenantId,
};

// export default class AuthService {
//     userManager: UserManager

//     constructor() {

//     public signInRedirect() {
//       return this.userManager.signinRedirect()
//     }

//     public signInCallback() {
//       return this.userManager.signinCallback()
//     }

//     public renewToken(): Promise<void> {
//       return this.userManager.signinSilentCallback()
//     }

//     public logout(): Promise<void> {
//       return this.userManager.signoutRedirect()
//     }

//     public getUser(): Promise<User | null> {
//       return this.userManager.getUser()
//     }
//   }
