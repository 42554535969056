import { computed, ref } from 'vue';
// import auth from '@/plugins/auth-service.js';

let _user = ref(null);

export default function useUserStore() {
  // const data = ref(null);
  // const error = ref(null);

  const isAuthenticated = computed(() => {
    return !!_user.value;
  });

  const userRole = computed(() => {
    if (!_user.value) {
      return null;
    }

    const roles =
      typeof _user.value.profile.role === 'string'
        ? [_user.value.profile.role]
        : _user.value.profile.role;

    return roles.find((r) => r.indexOf('beedigit') > -1);
  });

  const userLogin = computed(() => {
    if (!_user.value) {
      return null;
    }

    return _user.value.profile.preferred_username;
  });

  const userName = computed(() => {
    if (!_user.value) {
      return null;
    }

    return _user.value.profile.name;
  });

  const userEmail = computed(() => {
    if (!_user.value) {
      return null;
    }

    return _user.value.profile.name;
  });

  const userCreatedAt = computed(() => {
    if (!_user.value) {
      return null;
    }

    return Date.now();
  });

  const companyName = computed(() => {
    if (!_user.value) {
      return null;
    }

    return _user.value.profile.company_name;
  });

  const companyId = computed(() => {
    if (!_user.value) {
      return null;
    }

    return _user.value.profile.company_id;
  });

  const companies = computed(() => {
    if (!_user.value) {
      return null;
    }
    const company_infos = _user.value.profile.company_info;

    const companies = Array.isArray(company_infos) ? company_infos : [company_infos];
    return companies.map((company) => ({
      id: company.Id,
      name: company.Name,
    }));
  });

  // auth
  //   .getUser()
  //   .then((user) => {
  //     debugger;
  //     console.log(user);

  //     data.value = user;
  //   })
  //   .catch((e) => {
  //     error.value = e;
  //   });

  function init(user) {
    _user.value = user;
  }

  function reset() {
    _user.value = null;
  }

  return {
    init,
    reset,
    isAuthenticated,
    userRole,
    userName,
    userEmail,
    userLogin,
    companyName,
    companyId,
    companies,
    userCreatedAt,
  };
}
