import { noSpacesInInput } from '@/utils/utils.js';
import { validationMixin } from 'vuelidate';
import { validationMessagesExtractor } from '@/utils/vuelidate-messages.js';
import { validateStateForm } from '@/utils/formStateValidator.js';
import { required, email } from 'vuelidate/lib/validators';
import IpService from '@/api/ip-service';
import CountriesSelect from '@/components/forms/CountriesSelect';
import TimeZoneSelect from '@/components/forms/TimeZoneSelect.vue';
import registerCompany from '@/graphql/companies/mutations/registerCompany.gql';
export default {
  mixins: [validationMixin],
  components: {
    CountriesSelect,
    TimeZoneSelect
  },
  props: {
    modalId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    show: false,
    countryByIp: '',
    loading: false,
    form: {
      email: '',
      company: '',
      timeZone: '',
      country: ''
    }
  }),
  validations: {
    form: {
      company: {
        required
      },
      country: {
        required
      },
      timeZone: {
        required
      },
      email: {
        required,
        email
      }
    }
  },
  mounted() {
    IpService.getCountryByIp().then(data => {
      this.countryByIp = data.country_code.toLowerCase();
    }).catch(e => {
      this.countryByIp = '';
      console.log(e);
    });
  },
  computed: {
    disabledSave() {
      return this.$v.$invalid;
    },
    errorMessages() {
      return {
        company: {
          required: () => this.$t('message.error.emptyCompanyName')
        },
        country: {
          required: () => this.$t('message.error.emptyCountry')
        },
        timeZone: {
          required: () => this.$t('message.error.emptyTimeZone')
        },
        email: {
          required: () => this.$t('message.error.emptyLogin'),
          email: () => this.$t('message.error.invalidEmail')
        }
      };
    }
  },
  methods: {
    noSpacesInInput,
    getValidationMessage(field) {
      return validationMessagesExtractor(this.$v.form, this.errorMessages)(field);
    },
    validateState(name) {
      return validateStateForm(this.$v.form)(name);
    },
    onBlur(name) {
      this.$v.form[name].$touch();
    },
    resetForm() {
      this.form = {
        email: '',
        company: '',
        country: this.countryByIp,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      this.$v.$reset();
    },
    onShow() {
      this.loading = false;
      this.resetForm();
    },
    onCancel() {
      this.resetForm();
    },
    onSave() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.addNewCompany(this.form);
    },
    showErrorToast(message) {
      this.$bvToast.toast(message, {
        title: this.$t('toast.error'),
        variant: 'danger',
        solid: true
      });
    },
    showSuccessToast(message) {
      this.$bvToast.toast(message, {
        title: this.$t('toast.success'),
        variant: 'success',
        solid: true
      });
    },
    addNewCompany({
      company,
      country,
      timeZone,
      email
    }) {
      this.$apollo.mutate({
        mutation: registerCompany,
        variables: {
          createParams: {
            name: company,
            country,
            timeZone,
            email
          }
        }
      }).then(({
        data
      }) => {
        this.showSuccessToast(this.$t('companies.toast.companyAdded'));
        this.loading = false;
        this.$emit('saved', data.registerCompany.id);
        this.onClose();
      }).catch(e => {
        this.showErrorToast(this.$t('companies.toast.companyNotAdded'));
        console.log(e.message);
      });
    },
    onClose() {
      this.show = false;
    }
  }
};