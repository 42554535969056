import "core-js/modules/es.array.push.js";
import { computed, getCurrentInstance } from 'vue';
import auth from '@/plugins/auth-service.js';
import useAbility from '@/plugins/useAbility';
import DefaultPhotoSmall from '@/assets/images/faces/default-user-small.svg';
import { USER_ACTIONS, USER_SUBJECTS } from '@/utils/const';
import useUserStore from '@/composables/useUserStore';
import useCompanies from '@/composables/useCompanies.query';
import initials from 'initials';
import { useI18n } from '@/plugins/i18n';
import { useRouter } from 'vue-router/composables';
export default {
  __name: 'SidebarProfileDropdownBody',
  props: {
    userPhoto: {
      type: String
    },
    userName: {
      type: String
    }
  },
  emits: ['chooseProfileItem', 'chooseCompanyItem'],
  setup(__props, {
    emit
  }) {
    const {
      t
    } = useI18n();
    const router = useRouter();
    const instance = getCurrentInstance();
    const bvModal = instance.proxy.$bvModal;
    const {
      can
    } = useAbility();
    const {
      companyId: currentCompanyId
    } = useUserStore();
    const {
      result: companies
    } = useCompanies();
    const userCompanies = computed(() => {
      return companies.value;
    });
    const currentCompany = computed(() => {
      return userCompanies.value?.find(item => item.id == currentCompanyId.value);
    });
    const mainCompanies = computed(() => {
      return userCompanies.value?.filter(item => item.id !== 'DEFLT') || [];
    });
    const defaultCompany = computed(() => {
      return userCompanies.value?.find(item => item.id === 'DEFLT');
    });
    const pagesItems = computed(() => {
      return [{
        title: t('sidebar.titles.general'),
        routeName: 'GeneralSettingsCompany',
        ability: {
          subject: USER_SUBJECTS.COMPANY_SETTINGS,
          action: USER_ACTIONS.READ
        }
      }, {
        title: t('sidebar.titles.users'),
        routeName: 'Users',
        ability: {
          subject: USER_SUBJECTS.COMPANY_USERS,
          action: USER_ACTIONS.READ
        }
      }, {
        title: t('sidebar.titles.beedigitApi'),
        routeName: 'BeedigitApi',
        ability: {
          subject: USER_SUBJECTS.API_KEY,
          action: USER_ACTIONS.READ
        }
      }].filter(item => {
        return !item?.ability || can(item.ability.action, item.ability.subject);
      }) || [];
    });
    const profilePagesItems = computed(() => {
      return [{
        title: t('sidebar.titles.profile'),
        routeName: 'SettingsProfile'
      }, {
        title: t('sidebar.titles.password'),
        routeName: 'Password'
      }];
    });
    function checkIsCurrentCompany(id) {
      return id === currentCompanyId.value;
    }
    async function updateCurrentCompany(companyId) {
      try {
        auth.changeTenantId(companyId);
        await router.push({
          name: 'Boxes'
        });
      } catch (e) {
        console.log(e);
      } finally {
        router.go();
      }
    }
    function openNewCompanyModal() {
      bvModal.show('newCompanyModal');
    }
    async function companyCreated(companyId) {
      await updateCurrentCompany(companyId);
    }
    function chooseCompanyItem() {
      emit('chooseCompanyItem');
    }
    function chooseProfileItem() {
      emit('chooseProfileItem');
    }
    function logout() {
      auth.signoutRedirect();
    }
    return {
      __sfc: true,
      emit,
      t,
      router,
      instance,
      bvModal,
      can,
      currentCompanyId,
      companies,
      userCompanies,
      currentCompany,
      mainCompanies,
      defaultCompany,
      pagesItems,
      profilePagesItems,
      checkIsCurrentCompany,
      updateCurrentCompany,
      openNewCompanyModal,
      companyCreated,
      chooseCompanyItem,
      chooseProfileItem,
      logout,
      DefaultPhotoSmall,
      initials
    };
  }
};